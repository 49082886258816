<template>
  <div>
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="5">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            退款详情
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="warning">
        <p>同意退款,退款金额:￥{{ order_info.pay_fee }}</p>
      </div>
      <div class="header"><div class="title">用户信息</div></div>
      <el-form label-width="120px" class="left margin_left">
        <el-form-item label="用户ID:"><div>{{ order_info.user_id }}</div></el-form-item>
        <el-form-item label="姓名:"><div>{{ order_info.consignee }}</div></el-form-item>
        <el-form-item label="手机号:"><div>{{ order_info.mobile }}</div></el-form-item>
        <el-form-item label="车费详情:"><div>{{ order_info.car_str }}</div></el-form-item>
        <el-form-item label="服务地址:"><div>{{ order_info.area }}{{ order_info.address }}</div></el-form-item>
      </el-form>
      <div class="header"><div class="title">技师信息</div></div>
      <el-form label-width="120px" class="left margin_left">
        <el-form-item label="技师:"><div>{{ order_info.jishi_info.jishi_name }}</div></el-form-item>
        <el-form-item label="技师头像:" v-if="order_info.jishi_info.gzxxz_img">
          <el-image
            style="width: 100px; height: 100px"
            :src="order_info.jishi_info.gzxxz_img"
            fit="fill">
          </el-image>
        </el-form-item>
      </el-form>
      <div class="header"><div class="title">订单信息</div></div>
      <el-form label-width="120px" class="left margin_left">
        <el-form-item label="支付方式:"><div>
          <el-tag type="info" v-if="order_info.pay_id===1">余额支付</el-tag>
          <el-tag type="success" v-if="order_info.pay_id===2">微信</el-tag>
          <el-tag type="primary" v-if="order_info.pay_id===3">支付宝</el-tag>
          <el-tag type="primary" v-if="order_info.pay_id===4">推广余额支付</el-tag>
        </div></el-form-item>
        <el-form-item label="付款订单号:"><div>{{ order_info.fk_bh }}</div></el-form-item>
        <el-form-item label="退款订单号:"><div>{{ order_info.pay_reverse_id ? order_info.pay_reverse_id : '无' }}</div></el-form-item>
        <el-form-item label="提交时间:"><div>{{ order_info.refund_time }}</div></el-form-item>
        <el-form-item label="审核时间:"><div>{{ order_info.refund_time }}</div></el-form-item>
        <el-form-item label="退款原因:"><div>{{ order_info.cancel_reason }}</div></el-form-item>
      </el-form>
      <div class="header"><div class="title">退款服务</div></div>
      <el-table
        class="margin_left"
        :data="order_info.service_list"
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column label="服务图片">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.service_logo"
              fit="fill">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="service_name" label="服务名称"></el-table-column>
        <el-table-column prop="service_number" label="服务数量"></el-table-column>
      </el-table>
      
      <div class="left margin_left">
        <div class="margin_tb">合计数量:{{ service_total }}</div>
        <el-button class="margin_tb" type="primary" @click="back">返回</el-button>
      </div>
      
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      order_id: 0,
      order_info: {
        jishi_info: {}
      },
      service_total: 0
    }
  },
  created() {
    this.order_id = this.$route.query.order_id
    this.getOrderInfo()
  },
  methods: {
    getOrderInfo() {
      var url = 'order/refund_detail';
      let params = {
        order_id: this.order_id,
      };
      this.fd_post(url, params).then((res) => {
        if(res.status) {
          this.order_info = res.detail;
          this.service_total = this.order_info.service_list.reduce((acc, item) => acc + item.service_number, 0);
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    back(){
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="scss" scoped>
@import './Refund_detail.vue.scss';
</style>